import BaseController from "../base_controller"

import imagesLoaded from 'imagesloaded/imagesloaded.js'
import Masonry from 'masonry-layout/dist/masonry.pkgd.js'

export default class extends BaseController  {

  initialize() {

    window.msnry = new Masonry('.grid', {
      itemSelector: '.grid-item',
      columnWidth: '.grid-sizer',
      percentPosition: true,
      horizontalOrder: true
    });

    imagesLoaded('.grid').on('progress', function() {
      window.msnry.layout();
    });
    
  }

  filterAll() {

    $('.grid-item').filter(function(index) {
      return $(this);
    }).show();

    return window.msnry.layout();
  }
  
  filter30() {
    $('.grid-item').filter(function(index) {
      return $(this);
    }).show();

    $('.grid-item').filter(function(index) {
      return $(this).find('.btn').data('amount') > 30;
    }).hide();

    return window.msnry.layout();
  }

  filter50() {
    $('.grid-item').filter(function(index) {
      return $(this);
    }).show();

    $('.grid-item').filter(function(index) {
      return $(this).find('.btn').data('amount') > 50;
    }).hide();

    return window.msnry.layout();
  }

  filter100() {
    $('.grid-item').filter(function(index) {
      return $(this);
    }).show();

    $('.grid-item').filter(function(index) {
      return $(this).find('.btn').data('amount') < 100 && $(this).find('.btn').data('amount') !== "0.0";
    }).hide();

    return window.msnry.layout();
  }

}