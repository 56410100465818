// base_controller.js
import { Controller } from "stimulus"

import Rails from '@rails/ujs';
Rails.start();
 
export default class extends Controller {

  deliveryDatePicker() {  
    $( "#line_item_delivery_date" ).datepicker({ minDate: 1, dateFormat: 'yy-mm-dd' })
  }

  currencyFormatter(amount) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    })

    return formatter.format(amount)
  }

}