import BaseController from "../base_controller"

export default class extends BaseController  {

  initialize() {
    this.deliveryDatePicker()

    if ($('#cart_frequency').length) {
      this.setupPaypalRecurring()
    } else {
      this.setupPaypalOneTime()
    }
  }

  setupPaypalRecurring() {

    const total = $('#cart_total').data('total');
    const frequency = $('#cart_frequency').data('frequency');
    const cycles = $('#cart_cycles').data('cycles');

    paypal.Button.render({
      env: "production",
      locale: 'en_US',
      client: { production: 'ARNZdG6A3ToTlyms5otCUKGEfmCEk3fF79klq_yAaWrQztO-XxmV0_5AU_dL_o4qguuInC26cJa1PGJp' },
      commit: true,
      style: { 
        size: 'large'
      },
      payment() {
        return new (paypal.Promise)(function(resolve, reject) {
        // Make an ajax call to get the Token. This should call your back-end,
        // which should invoke the PayPal Payment api to retrieve the Token.
        // When you have a Token, you need to call the `resolve` method, e.g `resolve(data.paymentID)`
        // Or, if you have an error from your server side, you need to call `reject`, e.g. `reject(err)`
        jQuery.post('/paypal/authorize', {
          amount: total,
          frequency: frequency,
          cycles: cycles,
          subdomain: 'gift'
        }).done(function(data) {
          resolve(data.token);
        }).fail(function(err) {
          reject('Creating payment failed');
        });
        });
    },

    onAuthorize(data) {
      //console.log 'The payment was authorized!'
      //console.log 'Payment token = ', data.paymentToken
      // At this point, the payment has been authorized, and you will need to call your back-end to complete the
      // payment. Your back-end should invoke the PayPal Payment Execute api to finalize the transaction.
    
      jQuery.post('/paypal/execute',
        {token: data.paymentToken}
        ).done(data => window.location = "/thank-you").fail(function(err) {

          alert('Create payment failed!');

      });
    },

    onCancel(data, actions) {
       alert('Payment Cancelled!');
    },

    onError(err) {
      console.log(err);
      // Show an error page here, when an error occurs
      alert('Create payment failed');
    }
  }, '#btn-paypal-recurring');
      
  }

  setupPaypalOneTime() {

    const total = $('#cart_total').data('total');

    const items = JSON.parse($("[data-binding=items]")[0].text);

    const isValid = function() {
      const validator = $('#email_form').validate({
        rules: {
          "cart[email]": { 
            required: true,
            email: true
          }
        }
      });
      
      if (validator.form()) {
        return true;
      } else {
        return false;
      }
    };

    const onChangeCartEmail = function(handler) {
      document.querySelector('#cart_email').addEventListener('change', handler);
    };

    const toggleButton = function(actions) {
      if (isValid()) { return actions.enable(); } else { return actions.disable(); }
    };

    paypal.Button.render({
      env: "production",
      locale: 'en_US',
      client: { production: 'ARNZdG6A3ToTlyms5otCUKGEfmCEk3fF79klq_yAaWrQztO-XxmV0_5AU_dL_o4qguuInC26cJa1PGJp' },
      commit: true,
      style: { size: 'large' },
      funding: { disallowed: [ paypal.FUNDING.CREDIT ] },

    validate(actions) {
      toggleButton(actions);

      return onChangeCartEmail(function() {
        toggleButton(actions);
      });
    },

    payment(data, actions) {
      // Make a call to the REST api to create the payment
      return actions.payment.create({ 
        payment: { transactions: [ { 
          amount: {
            total,
            currency: 'USD'
          }, 
          description: 'School of Choice Contribution', 
          item_list: { 
            items
          } } ]
      }});
    },

    onAuthorize(data, actions) {

      // Make a call to the REST api to execute the payment
      actions.payment.execute().then(response => jQuery.post('/contributions', {
        token: data.paymentToken,
        payment_id: response.transactions[0].related_resources[0].sale.id
      }
      ).done(function(data) {
        
        window.location = "/thank-you";

    }).fail(function(err) {

        //this.resetForm();

      }));
    },

    onCancel(data, actions) {
      return alert('Payment was cancelled.');
    },
    onError(err) {
      return alert('There was an error.');
    }


    }, '#btn-paypal-one-time');
  }

  deliveryDatePicker() {
    $('.line_item_delivery_date').each(function() {
      return $(this).datepicker({minDate: 1, dateFormat: 'yy-mm-dd'})
    });
  }

  reloadPage(e) {
    location.reload();
  }

  onSizeSelect(e) {
    var form = $(e.target).closest('form')
    var target_input = $(form).find('.size_input')
    var size = $("option:selected", e.target).val()
    target_input.val(size)
  }

  onGreetingAttachedChange(e) {
    var form = $(e.target).closest('form')

    if (e.target.checked) {
      $(form).find('.category_selection').show()
    } else {
      $(form).find('.category_selection').hide()
      this.resetGreetingDetails(form)
    }
  }

  onGreetingCategorySelected(e){
    var form = $(e.target).closest('form')

    $(form).find('.category').each(function() {
      return $(this).hide();
    });

    const category_selected = $('option:selected', e.target).val();

    if (category_selected !== "") {
      $(form).find('.greeting_selection').show();
      $(form).find('.category_' + category_selected).show();
    } else {
      this.resetGreetingDetails(form);
    }
  }

  resetGreetingDetails(form) {
    $(form).find('.category_select').val('')
    $(form).find('.greeting-radio:checked').attr('checked', false)
    $(form).find('.greeting_selection').hide()
    $(form).find('.category_selection').hide()
    $(form).find('.attach_greeting_card').attr('checked', false)
  }

  setupPaypal() {
    var total = $('#cart_total').data('total')
    var items = JSON.parse($("[data-binding=items]")[0].text)
  }

 
}
