import BaseController from "../base_controller";

export default class extends BaseController {
  static targets = [
    "display_amount",
    "custom_amount",
    "quantity",
    "frequency",
    "cycles",
    "qty_students",
    "qty_portions",
  ];

  initialize() {
    this.deliveryDatePicker();
  }

  totalFixedItem() {
    var base_amount = Number($("#base_amount").data("base-amount"));
    var total_amount = base_amount * this.quantityTarget.value;

    this.changeDisplayAmount(total_amount);
  }

  totalCustomContribution() {
    this.changeDisplayAmount(Number(this.custom_amountTarget.value));
  }

  totalArtContribution() {
    var base_amount = Number($("#base_amount").data("base-amount"));
    var total_amount = Number(this.custom_amountTarget.value);

    $("#line_item_amount").val(total_amount);

    this.changeDisplayAmount(Number(total_amount));
  }

  calculateTotal() {
    var gift_id = $("#gift_id").data("gift-id");

    if (gift_id <= 10 || gift_id == 13) {
      this.totalFixedItem();
    }

    if (gift_id == 12) {
      this.totalCustomContribution();
    }
  }

  changeDisplayAmount(amount) {
    if (!this.isRecurring()) {
      this.display_amountTarget.textContent = this.currencyFormatter(amount);
    } else {
      this.display_amountTarget.textContent =
        this.currencyFormatter(amount) +
        " per " +
        this.frequencyTarget.value.toLowerCase();
    }
  }

  isRecurring() {
    if ($("#check_recurring").length && this.frequencyTarget.value) {
      return true;
    } else {
      return false;
    }
  }

  onRecurringChange(e) {
    if (e.target.checked) {
      $("#line_item_recurring").val("true");
      $("#recurring-details").show();
    } else {
      $("#line_item_recurring").val("false");
      $(this.frequencyTarget).val("");
      $(this.cyclesTarget).val("");
      $("#recurring-details").hide();
    }

    this.calculateTotal();
  }

  onFrequencyChange(e) {
    if (e.target.value == "") {
      $(this.cyclesTarget).val("");
    } else {
      this.calculateTotal();
    }
  }

  onSponsorshipTypeChange(e) {
    if (e.target.value == "full") {
      $(".one_time").val(2400);
      $(".recurring").val(200);

      $("#portion_selection").hide();
      $("#portion_select").val("");

      if ($("#full_selection").length) {
        $("#full_selection").show();
      } else {
        $("#one_time_label").text(`One Time Contribution ($2,400 per year)`);
        $("#recurring_label").text(
          `Recurring Contribution ($200 per month for 1 year)`
        );

        $("#payment_type_selection").show();
      }

      $("#line_item_level").val(2);
    } else if (e.target.value == "partial") {
      $(".one_time").val(360);
      $(".recurring").val(30);

      if ($("#full_selection").length) {
        $("#full_select").val("");
        $("#full_selection").hide();
      }

      $("#payment_type_selection").hide();

      $("#portion_selection").show();
      $("#line_item_level").val(1);
    } else {
      this.resetSponsorshipType();
    }
  }

  onStudentQuantityChange(e) {
    var quantity = e.target.value;

    $(".payment_option:checked").prop("checked", false);

    if (quantity != "") {
      $("#line_item_quantity").val(quantity);

      $("#one_time_label").text(
        `One Time Contribution (${this.currencyFormatter(
          2400 * quantity
        )} per year)`
      );
      $("#recurring_label").text(
        `Recurring Contribution (${this.currencyFormatter(
          200 * quantity
        )} per month for 1 year)`
      );

      $("#payment_type_selection").show();
    } else {
      $("#payment_type_selection").hide();
    }
  }

  onPortionQuantityChange(e) {
    var quantity = e.target.value;

    $(".payment_option:checked").prop("checked", false);

    if (quantity != "") {
      $("#line_item_quantity").val(quantity);

      $("#one_time_label").text(
        `One Time Contribution (${this.currencyFormatter(
          360 * quantity
        )} per year)`
      );
      $("#recurring_label").text(
        `Recurring Contribution (${this.currencyFormatter(
          30 * quantity
        )} per month for 1 year)`
      );

      $("#payment_type_selection").show();
    } else {
      $("#payment_type_selection").hide();
    }
  }

  onRecipientAttachedChange(e) {
    if (e.target.checked) {
      $("#line_item_gifted").val("true");
      $("#recipient-details").show();
    } else {
      $("#line_item_gifted").val("false");
      this.resetRecipientDetails();
    }
  }

  onGreetingAttachedChange(e) {
    if (e.target.checked) {
      $("#category_selection").show();
    } else {
      $("#category_selection").hide();
      this.resetGreetingDetails();
    }
  }

  onGreetingCategorySelected(e) {
    $(".category").each(function () {
      return $(this).hide();
    });

    const category_selected = $("option:selected", e.target).val();

    if (category_selected !== "") {
      $("#greeting_selection").show();
      $("#" + category_selected).show();
    } else {
      this.resetGreetingDetails();
    }
  }

  onSizeSelect(e) {
    $("#size_input").val(e.target.value);
  }

  oneTimeSelected() {
    $("#line_item_recurring").val("false");
  }

  recurringSelected() {
    $("#line_item_recurring").val("true");
  }

  addToCart(e) {
    e.preventDefault();

    $(e.target).prop("disabled", false);

    var form = $(e.target).closest("form");

    var isValidForm = this.formValidator();

    if (isValidForm) {
      $.ajax({
        url: $("form").attr("action"),
        type: "POST",
        dataType: "json",
        beforeSend(xhr) {
          xhr.setRequestHeader(
            "X-CSRF-Token",
            $('meta[name="csrf-token"]').attr("content")
          );
        },
        data: $("form").serialize(),
        error(jqXHR, textStatus, errorThrown) {
          const responseText = $.parseJSON(jqXHR.responseText);

          $("#errors ul").empty();
          $("#errors ul").append("<li>" + responseText + "</li>");
          $("#errors").show();
          $(e.target).prop("disabled", false);
          $(window).scrollTop(0);
        },
        success() {
          return $("#checkout_prompt").modal("show").css("z-index", 9999);
        },
      });
    } else {
      $(e.target).prop("disabled", false);
    }
  }

  resetRecipientDetails() {
    $("input[type=text], input[type=email], input[type=number], textarea").val(
      ""
    );
    $("#recipient-details").hide();
  }

  resetGreetingDetails() {
    $("#category_select").val("");
    $("#greeting-radio:checked").prop("checked", false);
    $("#greeting_selection").hide();
    $("#category_selection").hide();
    $("#attach_greeting_card").prop("checked", false);
  }

  resetSponsorshipType() {
    $("#sponsorship_type_select").val("");

    $("#full_select").val("");
    $("#full_selection").hide();

    $("#portion_select").val("");
    $("#portion_selection").hide();

    $("#payment_type_selection").hide();
  }

  formValidator() {
    const validator = $("#new_line_item").validate({
      rules: {
        "line_item[recipient_name]": "required",
        "line_item[recipient_email]": {
          required: true,
          email: true,
        },
        "line_item[delivery_date]": "required",
        category_select: "required",
        "line_item[greeting_id]": "required",
        "line_item[note]": "required",
        "line_item[frequency]": "required",
        "line_item[cycles]": "required",
        "line_item[variant_option_ids][]": "required",
        size_select: "required",
        "line_item[address]": "required",
        "line_item[city]": "required",
        "line_item[state]": "required",
        "line_item[zip]": "required",
        "line_item[phone]": "required",
        payment_type: "required",
        "line_item[amount]": "required",
        sponsorship_type_select: "required",
        full_select: "required",
        portion_select: "required",
      },
    });

    if (validator.form()) {
      return true;
    } else {
      return false;
    }
  }
}
