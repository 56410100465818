import 'bootstrap'

require('jquery-ui/ui/widgets/datepicker');
require("jquery-validation");
require('jquery-ui/themes/base/all.css');

import 'vendors/jquery-rotate.js'
import 'vendors/lightbox-rotate.js'

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("controllers", true, /\.js$/)
application.load(definitionsFromContext(context))