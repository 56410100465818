import BaseController from "../base_controller"
 
export default class extends BaseController  {

  initialize() { 
    this.setupPaypalRecurring()
    this.setupPaypalOneTime()
  }

  onSponsorshipTypeChange(e) {

    var student_qty = $('#qty_students').data('qty-students')

    if (e.target.value == 'full') {
      $('.one_time').val(2400 * student_qty)
      $('.recurring').val(200 * student_qty)

      $('#portion_selection').hide()
      $('#portion_select').val('')

      $('#one_time_label').text(`Annual Contribution (${ this.currencyFormatter(2400 * student_qty) } per year)`)
      $('#recurring_label').text(`Monthly Contribution (${ this.currencyFormatter(200 * student_qty) } per month) for 12 months`)

      $('#payment_type_selection').show()

      $('#line_item_level').val(2)

    } else if (e.target.value == 'partial') {

      $('.one_time').val(360 * student_qty)
      $('.recurring').val(30 * student_qty)

      $('#payment_type_selection').hide()

      $('#portion_selection').show()
      $('#line_item_level').val(1)

    } else {
      this.resetSponsorshipType()
    }
  }

  onPortionQuantityChange(e) {
    var portion_qty = e.target.value

    var student_qty = $('#qty_students').data('qty-students')

    if (portion_qty != '') {
      $('.one_time').val(360 * student_qty * portion_qty)
      $('.recurring').val(30 * student_qty * portion_qty)

      $('#one_time_label').text(`Annual Contribution (${ this.currencyFormatter(360 * portion_qty * student_qty) } per year)`)
      $('#recurring_label').text(`Monthly Contribution (${ this.currencyFormatter(30 * portion_qty * student_qty) } per month) for 12 months`)

      $('#payment_type_selection').show()
    } else {
      $('#payment_type_selection').hide()
    }
  }

  oneTimeSelected() {
    $('#line_item_recurring').val('false')
    $('#line_item_frequency').val('YEAR')
    $('#line_item_cycles').val(1)
    $('#btn-paypal-recurring').hide()
    $('#paypal_details').hide()
    $('#btn-paypal-one-time').show()
  }

  recurringSelected() {
    $('#btn-paypal-one-time').hide()
    $('#line_item_recurring').val('true')
    $('#line_item_frequency').val('MONTH')
    $('#line_item_cycles').val(12)
    
    $('#btn-paypal-recurring').show()

    $('#paypal_details').show()
  }

  autoRenewalSelected(e) {
    if(e.target.checked){
      $('#line_item_recurring').val('true')
      $('#line_item_cycles').val(0)
      $('#btn-paypal-one-time').hide()
      $('#btn-paypal-recurring').show()
      $('#paypal_details').show()
    } else {
      if($('#line_item_frequency').val() == 'MONTH'){
        $('#line_item_cycles').val(12)
      } else {
        $('#line_item_recurring').val('false')
        $('#line_item_cycles').val(1)
        $('#btn-paypal-recurring').hide()
        $('#btn-paypal-one-time').show()
        $('#paypal_details').hide()
      }
    }
  }

  setupPaypalRecurring() {

    const isValid = () => $('#form-validated').val() === String(true);

    const toggleButton = function(actions) {
      if (isValid()) { return actions.enable(); } else { return actions.disable(); }
    };

    return paypal.Button.render({
      env: "production",
      locale: 'en_US',
      client: { production: 'ARNZdG6A3ToTlyms5otCUKGEfmCEk3fF79klq_yAaWrQztO-XxmV0_5AU_dL_o4qguuInC26cJa1PGJp' },
      commit: true,
      style: { 
        size: 'medium',
        tagline: 'false'
      },

      validate(actions) {
        toggleButton(actions);
        const observer = new MutationObserver(mutations => toggleButton(actions)); 

        return observer.observe($("#form-validated")[0], {attributes: true, attributeOldValue: false, characterData: false, childList: false});
      },

      onClick() {
        this.formValidation()
      },

      payment() {
        const total_amount = Number($('.payment_option:checked').val());
        const frequency =  $('#line_item_frequency').val()
        const cycles = $('#line_item_cycles').val()

        $.ajax({ 
          url: $('form').attr('action'),
          type: 'POST',
          beforeSend(xhr) {
            xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
          },
          data: $('form').serialize(),
          error(jqXHR, textStatus, errorThrown) {
            return alert('Creating payment failed');
          },
          
          success(data, textStatus, jqXHR) {
            return this.updateLineItem(data["id"]);
          }
        });
          
        return new (paypal.Promise)(function(resolve, reject) {
          // Make an ajax call to get the Token. This should call your back-end,
          // which should invoke the PayPal Payment api to retrieve the Token.
          // When you have a Token, you need to call the `resolve` method, e.g `resolve(data.paymentID)`
          // Or, if you have an error from your server side, you need to call `reject`, e.g. `reject(err)`
          jQuery.post('/paypal/authorize', {
            amount: total_amount,
            frequency: frequency,
            cycles: cycles
          }
          ).done(function(data) {
            resolve(data.token);
          }).fail(function(err) {
            reject('Creating payment failed');
          });
          });
      },

    onAuthorize(data) {
      //console.log 'The payment was authorized!'
      //console.log 'Payment token = ', data.paymentToken
      // At this point, the payment has been authorized, and you will need to call your back-end to complete the
      // payment. Your back-end should invoke the PayPal Payment Execute api to finalize the transaction.
    
      jQuery.post('/paypal/execute',
        {token: data.paymentToken}
        ).done(data => window.location = "/thank-you").fail(function(err) {

          alert('Create payment failed!');

      });
    },

    onCancel(data, actions) {
       alert('Payment Cancelled!');
    },

    onError(err) {
      console.log(err);
      // Show an error page here, when an error occurs
      alert('Create payment failed');
    }
  }, '#btn-paypal-recurring');
      
  }

  setupPaypalOneTime() {

    const isValid = () => $('#form-validated').val() === String(true);

    const toggleButton = function(actions) {
      if (isValid()) { return actions.enable(); } else { return actions.disable(); }
    };

    paypal.Button.render({
      env: "production",
      locale: 'en_US',
      client: { production: 'ARNZdG6A3ToTlyms5otCUKGEfmCEk3fF79klq_yAaWrQztO-XxmV0_5AU_dL_o4qguuInC26cJa1PGJp' },
      commit: true,
      style: { size: 'large' },
      funding: { disallowed: [ paypal.FUNDING.CREDIT ] },

    validate(actions) {
      toggleButton(actions);
      const observer = new MutationObserver(mutations => toggleButton(actions)); 

      return observer.observe($("#form-validated")[0], {attributes: true, attributeOldValue: false, characterData: false, childList: false});
    },

    payment(data, actions) {

      var total_amount = Number($('.payment_option:checked').val())

      $.ajax({ 
        url: $('form').attr('action'),
        type: 'POST',
        beforeSend(xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        data: $('form').serialize(),
        error(jqXHR, textStatus, errorThrown) {
          return alert('Creating payment failed');
        },
        
        success(data, textStatus, jqXHR) {
          return this.updateLineItem(data["id"]);
        }
      });

      // Make a call to the REST api to create the payment
      return actions.payment.create({ 
        payment: { transactions: [ { 
          amount: {
            total: total_amount,
            currency: 'USD'
          }, 
          description: 'School of Choice Contribution'
        }]}
      });
    },

    onAuthorize(data, actions) {

      // Make a call to the REST api to execute the payment
      actions.payment.execute().then(response => jQuery.post('/contributions', {
        token: data.paymentToken,
        payment_id: response.transactions[0].related_resources[0].sale.id
      }
      ).done(function(data) {
        
        window.location = "/thank-you";

    }).fail(function(err) {

        //this.resetForm();

      }));
    },

    onCancel(data, actions) {
      return alert('Payment was cancelled.');
    },
    onError(err) {
      return alert('There was an error.');
    }


    }, '#btn-paypal-one-time');
  } 

  formValidation() {
    const validator = $('#new_line_item').validate({
      rules: {
        "line_item[amount]": "required",
        "line_item[frequency]": "required",
        "line_item[cycles]": "required",
        "form_completed": "required",
        "payment_type": "required",
        "cart[email]": { 
          required: true,
          email: true
        },
        "sponsorship_type_select": "required",
        "full_select": "required",
        "portion_select": "required",
        "payment_type_selection": "required"
      }
    });

    if (validator.form()) {
      return $('#form-validated').val("true");
    } else {
      $('#form-validated').val("false");
      return $('#form_completed')[0].checked = false;
    }
  } 

  validateOnComplete(e) {
    return this.formValidation();
  }
}
